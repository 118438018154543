.company-data-list {
  padding: 5px 0 0;
  overflow: auto;
}
.company-total-info ul {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.company-total-info ul li {
  width: calc(100% / 3 - 30px / 3);
  margin: 0 0 15px 15px;
  padding: 16px 16px 16px 76px;
  background: #ffffff;
  border: 1px solid #eef0f2;
  border-radius: 12px;
  position: relative;
}
.company-total-info ul li img {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 49px;
}
.company-total-info ul li h5 {
  font-weight: 600;
  margin: 0 0 10px;
}
.company-total-info ul li .active p {
  font-weight: 600;
  color: #09a109;
}
.company-total-info ul li .trial p {
  font-weight: 600;
  color: #9d9d4e;
}
.company-total-info ul li p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #717e94;
}
.company-total-info ul li:nth-child(3n + 1) {
  margin-left: 0;
}
.company-data-sec {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eef0f2;
  border-radius: 12px;
}
.company-data-sec h4 {
  font-weight: 600;
}
.company-data-list table {
  width: 100%;
}
.company-data-list table th {
  background: #f6f6f6;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  text-align: left;
}
.company-data-list table td {
  border-bottom: 1px solid #eef0f2;
  padding: 18px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}
.company-data-list table th:last-child,
.company-data-list table td:last-child {
  width: 150px;
}
.company-data-list table select {
  padding: 4px 36px 4px 13px;
  background: #f6f6f6 url(../images/select-arrow-big.png) no-repeat center right 15px;
  border-radius: 8px;
  background-size: 14px;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: auto;
  outline: none;
}
.company-data-list table td button.btn-style {
  border-color: #d4d9de;
  color: #344054;
  background: #fff;
}
.company-name-sec {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.company-name-sec img {
  margin-right: 15px;
  max-width: 37px;
}
.company-name-sec span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  width: calc(100% - 52px);
}

@media screen and (max-width: 991px) {
  .company-data-list table td {
    padding: 13px 15px;
  }
  .company-data-list table th {
    padding: 10px 15px;
  }
  .company-data-sec {
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  .company-total-info ul li {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .company-data-list table {
    min-width: 650px;
  }
}
@media screen and (max-width: 480px) {
}
