@import '../../../src/assets/scss/variables';

// Payroll
.payroll-item {
  display: flex;
  border: 1px solid $grey;
  margin-top: 16px;
  //padding: 24px;
  justify-content: space-between;
  border-radius: 16px;

  @media (max-width: 520px) {
    flex-direction: column;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $primary-blue;
  }

  .run-payroll-item-content {
    width: 100%;
    padding: 24px;
    .title {
      color: $dark-blue;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .payroll-details {
      display: flex;
      justify-content: start;
      align-items: center;

      .subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $dark-blue;
        margin-bottom: 0;

        @media (max-width: 725px) {
          display: flex;
          flex-direction: column;
          .paragraph-item-text {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .run-payroll-actions {
    display: flex;
    align-items: center;
    padding-right: 24px;
    @media (max-width: 520px) {
      padding: 0 24px 24px;
      justify-content: start;
    }
  }
}

// Buttons
.btn-payroll {
  display: flex;
  justify-content: center;
  width: 100px;
  margin-right: 8px;

  &:hover {
    svg{
      path {
        stroke: #4253F0;
        fill: transparent;
      }
    }
  }

  // resume button
  &-resume {
    border-color: $primary-blue;
    color: $primary-blue;
    background: transparent;

    &:hover {
      color: white;
      background: $primary-blue;
    }
  }

  // view button
  &-view {
    border-color: $light-grey;
    color: $text-light;
    background: $light-grey;

    &:hover {
      border-color: $primary-blue;
      background: $light-grey;
      color: $primary-blue;
    }
  }

  // 3 dots "more" button
  &-more {
    width: 48px;
    padding: 12px;
    background: transparent;

    svg {
      color: $dark-grey;
    }

    .menu-item {
      background: transparent;
    }

    &:hover {
      background: $light-grey !important;
      border-radius: 8px;
    }

    &:disabled {
      background: red;
    }
  }

  svg {
    margin-right: 8px;
  }
}


// Media queries for smaller screens
//@media (max-width: 992px) {
//  .payroll-item {
//    .run-payroll-item-content {
//      //padding: 0;
//    }
//  }
//}
