// grey
$grey: #D4D9DE;
$light-grey: #F5F5F6;
$light-dark-grey: #FAF9F9;
$icon-dark-grey: #717E94;
//$background-grey:#EEF0F2; // should be refactored on design & merge with #F5F5F6. use $light-grey here.
$dark-grey: #333645;

// blue
$dark-blue: #1D1858;
$primary-blue: #4253F0;

// red
$red: #FC5371;

// dark
$dark: #000000DE;

// white
$white: #FCFCFC;

// beige
$beige: #B3B3B3;
$beige-border: #D4D4D4; // name??
$light-beige: #B3B3B3DE;

// green
$green: #16AB6D;

// red
$light-red: #F8D7DA66;

// text
$text-dark: $dark;
$text-dark-blue: $dark-blue;
$text-light: #344054;
$text-grey: #484848;
$text-light-grey: #A9ACB4;
$text-dark-dark: #292D32; // name??

// button
$button-grey: #F6F6F6;