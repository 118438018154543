.table_container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.clone_table {
  border-radius: 0;
  z-index: 99;
}

/* outlining item from the list */
.highlight {
  animation: flash 2s ease-in-out;
}

.highlight .sticky-col {
  background: transparent !important;
}

@keyframes flash {
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 0, 0.5); /* Yellow flash */
  }
}

.upload-payroll-btn {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.upload-payroll-btn img {
  filter: brightness(0) invert(1);
  margin: 0 7px 0 0;
  max-width: 20px;
}

.upload-payroll-btn:hover img {
  filter: brightness(1) invert(0);
}

.payroll-top {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 20px;
}
.payroll-top .payroll-result-count {
  width: 40%;
}
.run-payroll-data {
  /*border: 1px solid #EEF0F2;*/
  border-radius: 12px;
  /*padding: 24px;*/
}

.run-payroll-data-statement-content {
  /*padding: 24px;*/
  padding: 0;
  height: 100%;
}

.run-payroll-data.statement {
  padding: 0;
  /*margin-top: 24px;*/
}

.payroll-table table {
  width: 100%;
  position: relative;
}
.payroll-table table tbody tr:nth-child(1) {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  max-width: 100px;
}
.payroll-table table thead {
  position: sticky;
  top: 0;
  background: #f6f6f6;
  z-index: 1;
}
.statement-more-data-table-header {
  position: relative !important;
  z-index: 0 !important;
}

.payroll-table.stations table th {
  padding: 0;
}

.payroll-table table th {
  padding: 12px 15px;
  background: #f6f6f6;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  text-align: center;
}
.payroll-table.leaderboard table th:nth-child(2),
.payroll-table.organizations table th:nth-child(2),
.payroll-table.organizations table td:nth-child(2) {
  text-align: start;
}
.payroll-table.leaderboard table th:nth-child(1) {
  text-align: center;
}
.payroll-table.stations table th:nth-child(1) {
  width: 30%;
}
.payroll-table.stations table td:nth-child(1) {
  width: 30%;
  padding-left: 20px;
}
.payroll-table.stations table th:last-child {
  width: 140px;
}
.payroll-table.stations table td:last-child {
  width: 140px;
}
.payroll-table table th:nth-child(1) {
  /*padding-left: 24px;*/
  padding-left: 20px;
  text-align: left;
}
.payroll-table table th:nth-child(1) .leaderboard-top-table-header-item {
  justify-content: flex-start;
}
.payroll-table.preview table th:nth-child(1) .leaderboard-top-table-header-item,
.payroll-table.preview table th:nth-child(2) .leaderboard-top-table-header-item,
.payroll-table.preview table th:nth-child(3) .leaderboard-top-table-header-item {
  justify-content: flex-start !important;
}
.payroll-table.preview table td:nth-child(3),
.payroll-table.preview table td:nth-child(2),
.payroll-table.preview table td:nth-child(1) {
  text-align: left !important;
}
.payroll-table.preview table td {
  text-align: right !important;
}
.payroll-table.statement > table > tbody > tr > td:nth-child(4),
.payroll-table.statement > table > tbody > tr > td:nth-child(5),
.payroll-table.statement > table > tbody > tr > td:nth-child(6),
.payroll-table.statement > table > tbody > tr > td:nth-child(7),
.payroll-table.statement > table > tbody > tr > td:nth-child(11) {
  text-align: right !important;
}
.payroll-table.preview table th:last-child {
  width: auto;
}
.payroll-table.preview table th .leaderboard-top-table-header-item {
  justify-content: flex-end;
}
.payroll-table.statement table th:nth-child(2) .leaderboard-top-table-header-item,
.payroll-table.statement table th:nth-child(4) .leaderboard-top-table-header-item,
.payroll-table.statement table th:nth-child(5) .leaderboard-top-table-header-item,
.payroll-table.statement table th:nth-child(6) .leaderboard-top-table-header-item,
.payroll-table.statement table th:nth-child(7) .leaderboard-top-table-header-item,
.payroll-table.statement table th:nth-child(11) .leaderboard-top-table-header-item {
  justify-content: flex-end;
}
.payroll-table table th:last-child {
  width: 110px;
  text-align: center;
}
.payroll-table.statement table td {
  padding: 10px 12px;
}
.payroll-table table td {
  padding: 12px 15px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  position: relative;
  border-bottom: 1px solid #eef0f2;
}
.payroll-table table tr.need-hover:hover td {
  background: #ebf0fe !important;
}
.payroll-table table tr:hover td {
  background: #ebf0fe;
}
.payroll-table table tr:hover .statement-button-wrapper {
  content-visibility: visible;
}

.payroll-table table tr:hover .statement-name-block-tooltip-icon {
  visibility: initial;
  display: flex;
}
.payroll-table table tr:hover td.no-hover-effect .statement-first-block .statement-name-block {
  color: #4253f0 !important;
}
.payroll-table table tr:hover td.no-hover-effect {
  background: transparent;
}
.payroll-table table td strong {
  font-weight: 500;
  display: block;
}
.payroll-table table td span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  display: block;
  padding: 3px;
  z-index: 0;
}
.payroll-table.leaderboard table td:nth-child(1) {
  padding-left: 0;
  text-align: center;
}
.payroll-table.add-member-table {
  max-height: 70vh;
}
.payroll-table.preferences {
  max-height: 69vh;
}
.payroll-table.add-member-table table th:nth-child(2) {
  text-align: left;
}
.payroll-table.add-member-table table td:nth-child(2) {
  text-align: left;
}
.payroll-table.add-member-table table td:nth-child(1) {
  padding-left: 20px;
}
.payroll-table.add-member-table table td:last-child button {
  margin: auto;
}
.payroll-table.preferences table td:nth-child(1) {
  padding-left: 20px;
}
.payroll-table table td:nth-child(1) {
  padding-left: 67px;
  text-align: left;
}
.payroll-table table td:nth-child(1) img {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 24px;
}

.payroll-table table td:last-child {
  text-align: center;
}
.payroll-table table td:last-child a {
  text-decoration: none !important;
}
.payroll-table table td:last-child button + button {
  margin-left: 17px;
}

.payroll-upload {
  padding: 10px 0 0;
}
.payroll-upload h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1d1858;
  margin: 0 0 15px;
}

.payroll-table table td:last-child button {
  background: transparent;
}
.payroll-table table td:last-child button img {
  max-width: 20px;
}

.file-upload-cancel {
  color: #344054;
  border: 1px solid #d4d9de;
  border-radius: 8px;
  background: transparent;
}
.file-upload-cancel:hover {
  color: #fff;
  border-color: #4253f0;
  background: #4253f0;
}
.file-upload-after {
  margin-bottom: 15px;
  padding: 15px 30px;
  border: 1px solid #eef0f2;
  border-radius: 12px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.file-upload-after-name {
  position: relative;
  padding-left: 40px;
  width: 25%;
}
.file-upload-after-name img {
  position: absolute;
  max-width: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.file-upload-after-name span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  display: block;
}
.file-upload-after-name strong {
  font-weight: 500;
  display: block;
  color: #1d1858;
  word-break: break-all;
}
.file-upload-progress {
  width: calc(100% - 25% - 100px);
  padding: 0px 20px;
  padding-right: 60px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.file-upload-progress label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  margin-left: 10px;
  width: 40px;
  display: inline-block;
}
.file-upload-progress progress {
  width: calc(100% - 60px);
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
}
.file-upload-progress progress::-webkit-progress-bar {
  background: #eef0f2;
  border-radius: 8px;
}
.file-upload-progress progress::-webkit-progress-value {
  background: #4253f0;
  border-radius: 8px;
}
.payroll-edit img {
  max-width: 20px;
}
.payroll-data-table h6 {
  margin: 0;
}
.payroll-data-table.payroll-table table td:nth-child(1) img {
  max-width: 36px;
  min-width: 36px;
}
.payroll-data-table.payroll-table table td:last-child,
.payroll-data-table.payroll-table table th:last-child {
  text-align: center;
  max-width: 100px;
}

.run-payroll-height {
  height: 47vh;
}

.preview.payroll-data-table.payroll-table table td:last-child,
.preview.payroll-data-table.payroll-table table th:last-child {
  text-align: center;
  max-width: 100px;
}

.back-page button img {
  max-width: 20px;
  margin-right: 10px;
}
.back-page button {
  background: transparent;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4253f0;
}
.back-page {
  padding: 0;
  margin: 0;
}
.payroll-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  white-space: nowrap;
}
.payroll-header .title {
  display: flex;
  align-items: center;
}
.payroll-header .title h4 {
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.payroll-header .back-button {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.payroll-header .back-button svg {
  height: 15px;
  width: 15px;
}
.payroll-header .back-button svg path {
  stroke: #717e94;
}
.payroll-header .top-title-bar {
  flex: 0 50%;
}
.payroll-header .top-title-bar h2 {
  margin: 0 !important;
  font-weight: 400;
  white-space: nowrap;
}
.payroll-header .payroll-header-actions {
  display: flex;
  justify-content: right;
  flex: 0 30%;
}
.payroll-header .payroll-header-actions button:last-child {
  margin-right: 0;
}
.top-title-bar .btn-sec .download-payroll-btn {
  border: 1px solid #d4d9de;
  background: #fff;
  color: #344054;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.top-title-bar .btn-sec .download-payroll-btn:hover {
  background: #4253f0;
  border: 1px solid #4253f0;
  color: #fff;
  cursor: pointer;
}
.pay-history {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-fuel-btn {
  margin-right: 16px;
  color: #1d1858;
  background: #fff;
  border: solid 1px #eaeaea;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
}
.export-btn {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
}
.upload-fuel-no-margin {
  margin: 0 !important;
}
.upload-fuel-btn > svg,
.export-btn > svg {
  margin-right: 8px;
}
.upload-fuel-btn:hover svg path {
  stroke: #ffffff;
}
.upload-fuel-btn.svg-fill:hover svg path {
  fill: #ffffff;
}
.export-btn:hover svg {
  fill: #4253f0;
}
.export-btn svg path {
  fill: #ffffff;
}
.upload-fuel-btn:hover {
  color: #fff;
  background: #4253f0;
  border: solid 1px #4253f0;
}
.process-payroll-btn {
  height: 46px;
  box-sizing: border-box;
  justify-content: center;
}
.process-payroll-btn svg {
  margin-right: 0px !important;
}
.process-payroll-btn:disabled {
  cursor: default;
  width: 142.2px;
}
.download-payroll-btn {
  display: flex;
  align-items: center;
}
.download-payroll-btn svg {
  margin-right: 8px;
}
.payroll-data-table.payroll-table table th:not(:nth-child(1)),
.payroll-data-table.payroll-table table td:not(:nth-child(1)) {
  text-align: center;
}

.statement-button-wrapper {
  display: flex;
  content-visibility: hidden;
}

.statement-more-data-container td {
  padding: 0 !important;
  border-bottom: 1px solid #eef0f2 !important;
  width: 100% !important;
  max-width: 100% !important;
}
.statement-more-data-container.work-area th:nth-child(1) {
  width: 17% !important;
}
.statement-more-data-container.work-area td:nth-child(3) {
  width: 55% !important;
}
.statement-more-data-container.work-area td:nth-child(1) {
  width: 15% !important;
}
.statement-more-data-container.work-area td:nth-child(2) {
  width: 70% !important;
}
.statement-more-data-container.work-area td:nth-child(3) {
  width: 15% !important;
}

.payroll-table.work-area table td:nth-child(1) {
  padding-left: 20px !important;
}

.payroll-table.work-area table td:last-child {
  padding-right: 8px !important;
}

.statement-more-data-container td:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.statement-more-data-container {
  height: 100px !important;
  min-height: 100px !important;
}

.statement-more-data-container td div h3 {
  margin-bottom: 0;
}

.statement-more-data-container td div img {
  position: relative !important;
  min-width: 100px !important;
  height: 100px;
  left: 0 !important;
}

.statement.payroll-table table td:nth-child(2) {
  text-align: center !important;
}

.statement.payroll-table table th:nth-child(2) .leaderboard-top-table-header-item {
  justify-content: center !important;
}

.statement.payroll-data-table.payroll-table
  table
  th:not(:nth-child(1))
  .leaderboard-top-table-header-item {
  min-width: auto;
}

.statement.payroll-data-table.payroll-table table td:nth-child(8) .form-item.statement-input,
.statement.payroll-data-table.payroll-table table td:nth-child(9) .form-item.statement-input {
  width: 80px;
}

.payroll-table.leaderboard {
  max-height: initial;
}
.payroll-table.statement {
  max-height: none !important;
  margin-bottom: 25px;
}
.payroll-table {
  overflow: auto;
  max-height: 43vh;
  padding-right: 4px;
}

.expenses.payroll-table {
  max-height: 65vh;
}

.expenses-preview.payroll-table {
  max-height: 61vh !important;
}

.expenses-preview.payroll-table table th:not(:last-child) .leaderboard-top-table-header-item {
  justify-content: center;
}

.expenses-preview-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 12px 24px 0;
}

.expenses-preview-total {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #1d1858;
}

.payroll-table.companies {
  max-height: 64vh;
}

.payroll-table.applications {
  max-height: max-content;
}

.payroll-table::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.payroll-table::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 8px;
}

.payroll-table::-webkit-scrollbar-thumb:hover {
  background: #bdbfc4;
}

.statement-avatar,
.statement-avatar .MuiSvgIcon-root {
  width: 36px !important;
  height: 36px !important;
}

.statement-name-block.employees {
  display: flex;
  align-items: center;
}

.statement-name-block-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.statement-name-block-content div:first-child {
  max-width: calc(100% - 22px);
}

.statement-name-block-tooltip-icon {
  visibility: hidden;
}

.statement-name-block-content svg {
  cursor: pointer;
  margin-left: 4px;
}

.statement-name-block-content svg:hover path {
  stroke: #717e94;
}

.statement-name-block-tooltip .title {
  justify-items: center;
}
.statement-name-block-tooltip .title p {
  font-family: Gilroy;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 0 !important;
}

.statement-name-block-tooltip-item.threshold {
  height: auto;
  padding: 8px 16px;
}

.statement-name-block-tooltip-item.work-area-row {
  align-items: flex-start;
}

.statement-name-block-tooltip-item {
  height: 40px;
  border-bottom: 1px solid #eef0f2;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.statement-name-block-tooltip-item .title {
  padding: 5px 10px;
  display: block;
  align-items: center;
}

.statement-name-block-tooltip-item b {
  margin-left: 4px;
}

.statement-name-block-tooltip-item:last-child {
  border-bottom: 0;
}

.statement-name-block {
  width: 100%;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1d1858;
  margin-left: 12px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statement-pay-adjustment {
  width: 57px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statement-company {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #717e94;
}

.statement-first-block {
  display: flex;
}

.payroll-table.payroll-data-table.statement table td:last-child,
.payroll-table.payroll-data-table.statement table th:last-child {
  width: 59px;
  min-width: 59px !important;
}
.payroll-table.payroll-data-table.statement table td:last-child {
  width: 70px;
}
.payroll-table.payroll-data-table.statement table td:nth-child(1) {
  max-width: 225px;
}

.payroll-table.payroll-data-table table td:nth-child(1) {
  padding-left: 20px;
  max-width: 93px;
}

.statement-input input {
  padding: 9px;
  text-align: center;
}

.statement-input input[type="number"] {
  padding-right: 15px;
  position: relative;
}

.statement-input-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: 2px;
  top: 0;
}

.statement-input-btns svg {
  color: #667085;
  height: 18px;
}

.statement-input input:disabled {
  background: #eee;
}

.statement-input.fuel {
  width: 90px;
}

.statement-input.hours {
  width: 65px;
}

.statement-input {
  width: 60px;
  margin: auto;
}

.payroll-table.preview {
  overflow: auto;
  max-height: 61vh;
}

.payroll-table.employees {
  max-height: initial;
  overflow: inherit;
}

.payroll-table.employees {
  max-height: initial;
  overflow: inherit;
}

.payroll-table.employees table tbody tr.no-station {
  background: #fb4a591a;
}

.payroll-table.employees table tbody tr.inactive td:nth-child(2),
.payroll-table.employees table tbody tr.inactive td:nth-child(3),
.payroll-table.employees table tbody tr.inactive td:nth-child(4),
.payroll-table.employees table tbody tr.inactive td:nth-child(5),
.payroll-table.employees table tbody tr.inactive td .statement-first-block .statement-name-block {
  color: #1d185880;
}

.payroll-table.stations table tbody tr {
  cursor: default;
}

.payroll-table.stations table tbody tr:hover td {
  background: transparent;
}

.payroll-table.add-member-table table thead tr,
.payroll-table.add-member-table table tbody tr {
  height: auto !important;
}

.payroll-table table tbody tr {
  height: 44px;
  cursor: pointer;
  z-index: 0;
}

.statement-more-data-table table tbody tr {
  height: 40px;
}

.statement-more-data-table table thead tr {
  height: 36px !important;
}

.statement-more-data-table table tbody tr:first-child {
  height: 32px !important;
}

.statement-more-data-table table tbody tr td,
.statement-more-data-table table thead tr th {
  padding: 0px 24px !important;
}

.payroll-table table thead tr {
  height: 40px;
}

.document-more-btn-icon {
  color: #333645;
}

.document-more-btn.menu-item {
  background: transparent;
}

.statement-top {
  margin-top: 24px;
}

.statement-subtitle {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  margin-left: 4px;
  color: #1d1858;
}

.statement-subtitle-wrapper {
  display: flex;
  align-items: center;
}

.document-more-btn.statement:hover {
  background: #fceef5 !important;
}

.document-more-btn.statement:hover svg path {
  fill: #fc5371 !important;
}
.statement-background {
  background: #fcfcfc;
}
.payroll-table.statement table td.statement-more-table {
  padding: 12px 16px 16px !important;
  background: #fcfcfc;
}
.payroll-table.statement table tr.statement-more-data-container:hover {
  background: #fcfcfc;
}
.document-more-btn.margin-auto {
  margin: auto;
}
.document-more-btn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-more-btn:hover {
  background: #f5f5f6 !important;
  border-radius: 3px;
}
.icon-black svg path {
  fill: #344054;
}

.icon-black svg,
.icon-black img {
  width: 20px;
  height: 20px;
}
.integrations-icon {
  width: 16px;
  height: 16px;
}

.fuel-upload-menu-item.fuel img {
  width: 30px;
}

.fuel-upload-menu-item h6 {
  margin-left: 8px;
  margin-bottom: 0;
}

.more-menu.report,
.more-menu.time-card {
  margin-top: 5px;
}
.border-last-item {
  border-top: 1px solid #eef0f2 !important;
  color: #4253f0 !important;
}

.more-menu.time-card .MuiList-root {
  width: 165px;
}
.more-menu.time-card .MuiList-root button {
  max-width: 26px;
}
/*.more-menu.report .MuiList-root {*/
/*    width: auto;*/
/*}*/

.more-menu.report.fuel-modal button {
  margin-right: 4px;
}

/*.more-menu.report.statement .MuiList-root {*/
/*width: auto;*/
/*}*/

.more-menu .MuiList-root li {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
}
.more-menu .MuiList-root li:last-child {
  padding-bottom: 3px;
}
.payroll-table-select {
  width: 179px;
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #d4d9de;
}

.payroll-table-select .MuiOutlinedInput-notchedOutline {
  border: none;
}

.payroll-table-select .MuiSelect-icon {
  color: #344054;
}

.statement-more-data-table table tbody tr td {
  background-color: transparent !important;
  width: auto !important;
  border: 0.5px solid #eef0f2;
  border-bottom: 0.5px solid #eef0f2;
}

.statement-more-data-table table thead tr th {
  cursor: default !important;
  background: #7b87f51a;
  border-top: 1px solid #eef0f2;
  color: #344054;
}

.statement-more-data-table table thead tr th:first-child {
  border-right: 1px solid #ffffff;
  border-left: 1px solid #eef0f2;
}

.statement-more-data-table table thead tr th:last-child {
  border-right: 1px solid #eef0f2;
}

.statement-more-data-table table thead tr th:not(:last-child):not(:first-child) {
  border-right: 1px solid #ffffff;
}

.statement-more-data-table table tbody tr:first-child td {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #344054;
}

.statement-more-data-table table tbody tr:not(:first-child) td {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #344054;
}

.statement-more-data-table table tbody tr td:not(:nth-child(1)) {
  text-align: center !important;
}

@media screen and (max-width: 1199px) {
  .payroll-header {
    flex-direction: column;
    align-items: normal;
  }
  .file-upload-progress {
    padding-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .payroll-top-total {
    overflow: auto;
    height: 174px !important;
    justify-content: inherit !important;
  }
  .payroll-top-total-item {
    padding: 0 24px !important;
  }
  .run-payroll-data {
    /*border: 1px solid #EEF0F2;*/
    border-radius: 12px;
    padding: 20px 15px;
    margin-top: 7px;
  }
  .payroll-table {
    overflow: auto;
  }
  .payroll-table table {
    min-width: 700px;
  }
  .payroll-table table td {
    padding: 17px 10px;
  }
  .payroll-data-table.payroll-table table {
    min-width: 850px;
  }
  .payroll-table table td:nth-child(1) img {
    left: 10px;
  }
  .payroll-table table td:nth-child(1) {
    padding-left: 55px;
  }
}
@media screen and (max-width: 767px) {
  .file-upload-after {
    padding: 12px 15px;
  }
  .file-upload-after-name {
    width: 100%;
  }
  .file-upload-progress {
    padding: 0;
    width: 100%;
    margin: 10px 0;
  }
}
@media screen and (max-width: 480px) {
}
