.integration-app-sec {
  padding: 7px 0 0;
}
.integration-app-sec ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.integration-app-sec ul li {
  width: 100%;
  margin: 0 0 20px 0;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eef0f2;
  border-radius: 12px;
}
.logo-btn-sec {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.app-logo {
  display: flex;
}
.app-logo img {
  width: 40px;
  height: 40px;
}
.app-text {
  margin-left: 32px;
}
.app-text h5 {
  font-weight: 600;
}
.btn-style {
  border-radius: 8px;
}
.logo-btn-sec .btn-style {
  border-color: #eef0f2;
  color: #717e94;
  background: #fff;
}
.logo-btn-sec .btn-style.active,
.logo-btn-sec .btn-style:hover {
  border-color: #7b87f5;
  color: #4253f0;
  background: #ebf0fe;
}
.integration-app-sec.li-nth-child-3n-1-mr-0 ul li:nth-child(3n + 1) {
  margin-left: 0;
}
.integration-app-sec ul li h5 {
  margin: 0 0 11px;
}
.integration-app-sec ul li p {
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  margin: 0;
}
.integration-app-popup form.login-form .input-items input {
  padding: 9px 16px;
}
.popup-dis-sec.integration-app-form {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .integration-app-sec ul li {
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  .integration-app-sec ul li {
    width: 100%;
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 480px) {
}
