.profile-details {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  width: calc(100% + 48px);
  margin: 0 -24px -50px;
  min-height: calc(100% - 159px);
}
.profile-sidebar {
  width: 280px;
  padding: 0 24px;
  border-right: 1px solid #eef0f2;
}
.profile-dis {
  width: calc(100% - 280px);
  padding: 0 24px 50px;
}

.profile-dis.expenses-settings {
  padding-bottom: 0px;
}

.expenses-settings-header {
  max-width: 870px;
}

.expenses-settings-header .top-title-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expenses-settings-header .top-title-bar .btn-sec {
  width: 190px;
}

.expenses-settings-header .top-title-bar .title-sec {
  width: auto;
}

.profile-form {
  padding: 24px;
  border: 1px solid #eef0f2;
  border-radius: 16px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.profile-form.profile-info {
  padding: 16px 0 24px;
}
.profile-form h5 {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1d1858;
  margin: 0 0 -10px;
}
.profile-info-form-wrapper.password {
  flex-direction: column;
  width: 100%;
}
.profile-info-cancel {
  margin-right: 16px;
  border: 1px solid #d4d9de;
  background: #ffffff;
  color: #344054;
}
.profile-form .profile-info-form-wrapper.password .form-item {
  width: 100%;
}
.profile-info-modal-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  margin-right: 48px;
}
.profile-info-modal-btn-wrapper button {
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
}
.profile-info-form-wrapper {
  padding: 0 24px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.profile-info-form-title button {
  background: transparent;
}
.profile-info-form-title .companies-btn {
  width: 24px;
  height: 24px;
}
.my-profile-password-helper-text {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #717e94;
}
.profile-info-form-title {
  border-bottom: 1px solid #eaeaea;
  padding: 0 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-info-form-title .profile-info-form-title-text {
  color: #344054;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.profile-form-sec.password {
  margin-top: 28px;
}
.profile-form .profile-info-form-wrapper .form-item {
  width: calc(50% - 10px);
  margin: 35px 0 0;
}
.profile-form .profile-info-form-wrapper .form-item input {
  padding-left: 16px;
}
.profile-form-sec .profile-sec {
  padding: 13px 0 28px;
}
.profile-sec.profile-info {
  display: flex;
  margin-bottom: 20px;
}
.profile-info-text-block {
  margin-left: 28px;
  font-family: Gilroy;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: #1d1858;
}
.profile-info-text-block div:nth-child(2) {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
}
.companies-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.companies-list ul li .company-item {
  padding: 25px 23px 25px 16px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.settings-station-row:hover {
  background: #faf9f9;
  cursor: pointer !important;
}
.companies-list ul li .company-item:hover {
  background: #faf9f9;
  cursor: pointer;
}
.companies-list ul li {
  padding: 0;
  background: #ffffff;
  transition: all 0.35s ease-in-out;
  border: 1px solid #eef0f2;
  border-radius: 12px;
  margin: 0 0 24px;
}
.companies-list ul li .company-item .col-1 {
  width: calc(45% - 150px / 3);
  padding-right: 15px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.companies-list ul li .company-item .col-1 img {
  margin: 0 14px 0 0;
  border: 2px solid #ffffff;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.companies-list ul li .company-item .col-1 span {
  width: calc(100% - 50px);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1d1858;
}
.companies-list ul li .company-item .col-2 {
  width: calc(25% - 150px / 3);
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.companies-list ul li .company-item .col-3 {
  width: calc(30% - 150px / 3);
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.companies-list ul li .company-item .col-3 strong {
  color: #344054;
  font-weight: 500;
}
.companies-list ul li .company-item .col-4 {
  width: 150px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.companies-list ul li .company-item .col-4 a,
.companies-list ul li .company-item .col-4 button {
  text-decoration: none !important;
  padding: 5px;
  transition: all 0.35s ease-in-out;
}
.col-4 button {
  background: transparent;
  display: flex;
}

.companies-delete path {
  fill: #8d98a9;
}

.companies-trash img,
.delete-station img {
  max-width: 20px;
}
.companies-btn.edit:hover svg path {
  stroke: #4253f0;
}
.companies-btn.add-station {
  background: transparent;
  margin-left: auto;
  margin-right: 8px;
}
.companies-btn.delete:hover svg path {
  fill: #fc5371;
}
.companies-btn.applications {
  background: transparent;
}
.companies-btn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.companies-btn.coming-soon:disabled {
  color: #717e94;
  width: 117px;
  height: 36px;
  border-radius: 8px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.companies-btn.coming-soon:hover {
  border: 1px solid #4253f0;
  color: #4253f0;
  background-color: #f6f6f6 !important;
  cursor: pointer;
}

.companies-btn.connected:disabled {
  border: none;
  background-color: #e8f7f0 !important;
  color: #16ab6d;
  width: 105px;
  height: 36px;
  border-radius: 8px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.companies-btn.disconnect {
  border: none;
  background-color: #ebf0fe !important;
  color: #4253f0;
  width: 118px;
  height: 36px;
  border-radius: 8px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
}

.companies-btn.connect-now {
  border: none;
  background-color: #ebf0fe !important;
  color: #4253f0;
  width: 118px;
  height: 36px;
  border-radius: 8px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
}

.companies-btn.connect-now:hover {
  background-color: #d3dcf3 !important;
  border-radius: 8px;
  border: none;
}

.companies-btn:hover {
  background: #ebf0fe !important;
  border-radius: 3px;
}
.companies-btn.delete:hover {
  background: #fceef5 !important;
  border-radius: 3px;
}
.companies-btn svg {
  transition: all 0.5s linear;
}
.companies-btn.up svg {
  transform: rotate(180deg);
}
.companies-list ul li.item-opened {
  background: #f6f6f6;
}
.companies-list ul li.item-opened .company-item .col-4 a.companies-btn {
  transform: rotate(180deg);
}
.companies-informatio-sec {
  width: 100%;
  padding: 0;
}
.companies-informatio-sec .form-item input {
  padding: 13px 16px;
}
.companies-informatio-sec .form-item {
  margin: 0 0 25px;
}
.form-item.station-item {
  position: relative;
  padding-right: 96px;
}
.delete-station {
  position: absolute;
  right: 0;
  bottom: 16px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
button.delete-station {
  background: transparent;
}
.delete-station img {
  margin-right: 11px;
}
.companies-informatio-sec .add-more {
  padding: 0;
  margin-top: 24px;
}

.companies-informatio-sec .add-more button {
  width: 100%;
  height: 44px;
  background: #ebf0fe;
  color: #4253f0;
  text-decoration: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.auto-clock-in-enabled {
  width: 63px;
  height: 28px;
  background: #ebf0fe;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #005ed1;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.auto-clock-in-disabled {
  width: 75px;
  height: 28px;
  background: #f5f5f6;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #1d1858;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.auto-clock-in-container {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 45%;
}
.auto-clock-in-selects-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: auto;
  align-items: center;
}
.clock-in-time {
  width: 45%;
  margin-bottom: 4px;
}
.clock-in-time .employees-select {
  width: 100%;
}
.work-area-pay-rate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px 0;
}
.work-area-pay-rate-container > div {
  margin-right: 8px;
  height: auto;
  border-bottom: 0;
  padding: 0;
}
.clock-in-time-divider {
  width: 18px;
  height: 2px;
  background: #eaeaea;
  margin: 12px 12px 0;
}
.add-work-area-rate-container h6 {
  margin: 0;
}
.add-work-area-rate-container > .form-items-container .form-item:nth-child(2) {
  padding-right: 0;
}
.station-btns-wrapper {
  justify-content: flex-end !important;
}
.station-btns-wrapper,
.expenses-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-item.expenses {
  margin-top: 20px;
}

.input-box.expenses .payroll-table-select div {
  text-align: start;
}

.input-box.expenses .payroll-table-select {
  width: 100%;
}

.expenses-btns-wrapper .delete {
  margin-left: 8px;
}

.companies-informatio-sec .add-more button:hover {
  background: #d3dcf3;
}

.my-profile-dis {
  display: block;
}
.my-profile-dis.profile-info {
  height: 75vh;
  overflow: auto;
  padding-left: 44px;
  padding-right: 8px;
}
.my-profile-dis.profile-info::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.my-profile-dis.profile-info::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 8px;
}

.my-profile-dis.profile-info::-webkit-scrollbar-thumb:hover {
  background: #bdbfc4;
}

.settings-applications-company-row {
  margin-top: 24px;
  border: 1px solid #eef0f2;
  background: #fafafa;
  border-radius: 8px;
}

.applications-company-row-item {
  height: 72px;
  width: 100%;
  margin: 12px 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.applications-company-row-item-name {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1d1858;
  width: 35%;
}

.applications-company-row-item-text.co-code {
  margin-left: 32px;
}

.applications-company-row-item-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1d1858;
  width: 20%;
}

.applications-company-row-item-text span {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717e94;
  margin-right: 12px;
}

.paychex-checkbox {
  color: #d4d9de !important;
}

.paychex-checkbox.Mui-checked {
  color: #4253f0 !important;
}
.paychex-checkbox-label {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.paychex-checkbox-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.paychex-checkbox-container label {
  margin: 0;
}

.applications-company-row-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-right: 50px;
}

.subscription-plan {
  border: 1px solid #4253f0;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 42px;
}

.subscription-plan-header {
  border-bottom: 1px solid #4253f0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription-plan-header-tag {
  width: 90px;
  border-radius: 16px;
  background: #eef0f2;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscription-plan-header-tag.active {
  background: #e8f7f0;
  color: #16ab6d;
  width: 63px;
  height: 24px;
  border-radius: 16px;
}

.subscription-plan-header-icon {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #344054;
}

.subscription-plan-header-icon div {
  margin-left: 20px;
}

.subscription-plan-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 4px;
}

.subscription-plan-container.payment-method {
  justify-content: center;
}

.subscription-plan-cost {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
}

.subscription-plan-number {
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #344054;
  margin-bottom: 4px;
}

.subscription-plan-info {
  display: flex;
}

.subscription-plan-info-first {
  margin-right: 24px;
}

.subscription-plan-coupon-applied {
  color: #667085;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subscription-plan-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  color: #667085;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.subscription-plan-info-item svg {
  margin-right: 6px;
}

.subscription-plan-footer {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: rgba(235, 240, 254, 0.3);
}

.subscription-plan-footer.cancel {
  background: transparent;
  padding-top: 4px;
  justify-content: flex-end;
}

.subscription-plan-footer.cancel button {
  color: #8d98a9;
  padding: 0;
}

.subscription-plan-footer.cancel button:hover {
  background: none;
  text-decoration: underline;
}

.subscription-plan-footer button {
  padding-left: 8px;
  background: none;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #4253f0;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.payment-method-empty {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.payment-method-empty div {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #d4d9de;
  margin: 12px 0;
}

.subscription-plan-footer button:hover {
  background: #ecf4fc;
}

.subscription-plan-header-add svg {
  margin-right: 8px;
}

.subscription-plan-header-add {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #4253f0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 8px;
}

.subscription-plan-header-add:hover {
  background: rgba(235, 240, 254, 0.3);
}

.payroll-table.billing {
  max-height: none;
  padding-right: 0px;
}

.billing-download {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: #4253f0;
  background: none;
  padding: 8px;
}

.billing-download:hover {
  text-decoration: underline;
  color: #5263fa;
}

.billing-invoice {
  display: flex;
  align-items: center;
}

.billing-invoice svg {
  margin-right: 4px;
}

.billing-invoice div {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.payroll-table.billing table tr {
  cursor: default;
}

.payroll-table.billing table tr:hover td {
  background: none;
}

.payroll-table.billing table td:nth-child(1) {
  padding-left: 15px;
}

.payroll-table.billing table td:nth-child(2),
.payroll-table.billing table td:nth-child(3),
.payroll-table.billing table th:nth-child(3) {
  text-align: right;
}

.settings-users-avatar > img {
  left: 0 !important;
  max-width: 100% !important;
}

.payroll-table.billing table th:nth-child(2) div,
.payroll-table.billing table th:nth-child(3) div {
  justify-content: flex-end;
}

.subscription-billing {
  margin-bottom: 24px;
}

.subscription-tab {
  max-height: 80vh;
  overflow: auto;
  padding-right: 8px;
}

.subscription-tab::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.subscription-tab::-webkit-scrollbar-thumb {
  background-color: #4253f0;
  border-radius: 8px;
}

.subscription-tab::-webkit-scrollbar-thumb:hover {
  background: #5b6bff;
  cursor: pointer;
}

.subscription-billing-header {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #344054;
  margin-bottom: 24px;
}

.subscription-billing-subtitle {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717e94;
  margin-top: 4px;
}

.subscription-billing-empty {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subscription-billing-empty div {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #d4d9de;
  margin-top: 12px;
}

.profile-companies.expenses-settings .top-title-bar {
  padding-top: 16px;
}

.settings-expenses-content {
  display: flex;
  flex-direction: column;
  max-width: 870px;
  max-height: 70vh;
  overflow: auto;
  padding-right: 8px;
}

.settings-expenses-content::-webkit-scrollbar {
  width: 4px;
}

.settings-expenses-content::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 8px;
}

.settings-expenses-content::-webkit-scrollbar-thumb:hover {
  background: #bdbfc4;
}

.settings-expenses-content-item {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #4253f0;
  border-radius: 8px;
  margin-bottom: 16px;
}

.settings-expenses-content-item:last-child {
  margin-bottom: 0px;
}

.settings-expenses-content-item-left,
.settings-expenses-content-item-right {
  display: flex;
  align-items: center;
}

.settings-expenses-content-item-left svg {
  height: 40px;
}

.settings-expenses-content-item-left div {
  margin-left: 20px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #344054;
}

.settings-expenses-content-item-value {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #1d1858;
  display: flex;
  align-items: center;
}

.settings-expenses-content-item-value div {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #717e94;
}

.settings-expenses-content-item-right button {
  margin-left: 24px;
  background: transparent;
}

.subscription-payment-method-item {
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.subscription-payment-method-item.active {
  background: #ebf0fe4d;
}

.subscription-payment-method-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.subscription-payment-more-btn {
  background: transparent;
}

.subscription-payment-more-btn:hover {
  background: #f5f5f6 !important;
  border-radius: 3px;
}

.subscription-payment-method-item-icon {
  margin-right: 12px;
}

.subscription-payment-method-item-info {
  display: flex;
  align-items: flex-start;
}

.subscription-payment-method-item-info-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #667085;
}

.subscription-payment-method-item-info-ending {
  font-weight: 500;
  color: #344054;
}

@media screen and (max-width: 1199px) {
  .profile-details {
    width: calc(100% + 30px);
    margin: 0 -15px -50px;
  }
}
@media screen and (max-width: 991px) {
  .profile-sidebar {
    width: 230px;
    padding: 0 15px;
  }
  .profile-dis {
    width: calc(100% - 230px);
    padding: 0 15px 30px;
  }
}
@media screen and (max-width: 767px) {
  .profile-sidebar {
    width: 100%;
  }
  .profile-dis {
    width: 100%;
  }
  .companies-list ul li {
    padding: 5px 15px;
  }
  .companies-list ul li .company-item .col-1,
  .companies-list ul li .company-item .col-2,
  .companies-list ul li .company-item .col-3 {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #eef0f2;
  }
  .companies-list ul li .company-item .col-4 {
    width: 100%;
    padding: 15px 0;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
  }
  .profile-form .form-item {
    width: 100%;
  }
  .delete-station img {
    margin-right: 7px;
  }
  .form-item.station-item {
    padding-right: 83px;
  }
  .companies-informatio-sec {
    padding: 24px 0 18px;
  }
}

.confirm-modal-btn.coupon-btn {
  background: #4253f0 !important;
  color: #ffffff !important;
  width: 70px;
  height: 30px;
  border: 1px solid #4253f0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.confirm-modal-btn.coupon-btn:disabled {
  background: #c5c6d1 !important;
  border: 1px solid #c5c6d1 !important;
}

.confirm-modal-btn.coupon-btn:disabled:hover {
  background: #c5c6d1 !important;
  border: 1px solid #c5c6d1 !important;
  color: #ffffff !important;
  cursor: pointer;
}

.confirm-modal-btn.coupon-btn:hover {
  background: #ffffff !important;
  color: #4253f0 !important;
  cursor: pointer;
}

.coupon-container {
  display: flex;
  align-items: center;
}

.coupon-container .form-item {
  margin-bottom: 0;
  margin-right: 8px;
}

.coupon-container .form-item input {
  padding: 5px 16px;
}
