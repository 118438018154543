.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  min-height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1035);
  border-radius: 8px;
}

.missing-employee-list-container {
  max-height: 114px;
  overflow: auto;
  padding: 0 24px;
}

.missing-employee-list-container::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.missing-employee-list-container::-webkit-scrollbar-thumb {
  background-color: #4253f0;
  border-radius: 2px;
}

.missing-employee-list-container::-webkit-scrollbar-thumb:hover {
  background: #5766e7;
  cursor: pointer;
}

.add-missing-employee-btn-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 24px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 100;
}

.add-missing-employee-btn-group button {
  width: 48% !important;
  height: 40px;
  padding: 0;
}
.add-missing-employee-btn-group button.add-popup-cancel {
  border: 1px solid #d4d9de !important;
  background: #fff !important;
  color: #344054 !important;
}

.add-missing-employee-btn-group button.add-popup-cancel:hover {
  background: #f5f5f6 !important;
}

.add-missing-employee-btn-group button.add-popup-save {
  background: #4253f0 !important;
  color: #fff !important;
}

.add-missing-employee-btn-group button.add-popup-save:hover {
  background: #fff !important;
  border: 1px solid #4253f0 !important;
  color: #344054 !important;
}

.add-missing-employee-header {
  border-bottom: none;
}

.add-missing-employee-title {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 600;
  color: #1d1858;
}

.add-missing-employee-profile-count {
  color: #1d1858;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
}

.add-missing-employee-header-subtitle {
  padding: 0 24px;
  color: #1d1858;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
}

.add-missing-employee-profile {
  margin: 24px 0 0;
  padding: 0 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.add-missing-employee-profile-title {
  color: #1d1858;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
}

.employees-edit-popup .employees-inner.upload-gc-inner {
  max-width: 720px;
}

.confirm-modal.missing-employee {
  min-width: 424px;
}

.confirm-modal-subtitle.missing-employee {
  max-width: 376px;
  text-align: left;
  color: #1d1858;
}

.missing-employee-list,
.missing-employee-list-item {
  color: #1d1858;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.missing-employee-list {
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  padding: 0 24px;
}

.missing-employee-list svg {
  margin-left: 8px;
  cursor: pointer;
}

.missing-employee-list svg:hover path {
  stroke: #565472;
}

.missing-employee-list-item {
  margin: 8px 0;
}

.confirm-modal-wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  color: #333645;
  text-align: center;
  position: relative;
}

.confirm-modal-btn.company-btn {
  width: 48%;
}

.confirm-modal-btn {
  width: 182px;
  height: 47px;
  border-radius: 4px;
  background: #f5f5f6;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
}

.confirm-modal-btn.cancel:hover {
  background: #e7e7e9;
}

.confirm-modal-btn:hover {
  background: #e8e8f5;
}

.confirm-modal-btn.company {
  background: #14bd87;
  color: #e8f7f0;
  border: none;
}

.confirm-modal-btn.company:hover {
  background: #16d498;
  border: none;
}

.confirm-modal-btn.delete {
  background: #fb4a59;
  color: #ffffff;
}

.confirm-modal-btn.delete:hover {
  background: #fc6471;
}

.confirm-modal-btn.recalculate {
  background: #4253f0;
  color: #ffffff;
}
.confirm-modal-btn.recalculate:disabled {
  color: #000000;
}

.confirm-modal-btn.recalculate:hover {
  background: #ffffff;
  border: 1px solid #4253f0;
  color: #4253f0;
  cursor: pointer;
}

.confirm-modal-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-modal-icon-wrapper {
  width: 56px;
  height: 56px;
  background: #fb4a591a;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-modal-icon-wrapper.recalculate {
  background: #ebf0fe;
}

.confirm-modal-icon-wrapper.yellow {
  background: #fef8ec;
}

.confirm-modal-icon-wrapper.yellow .confirm-modal-icon path {
  fill: #f1ae15;
}

.confirm-modal-icon-wrapper.csa {
  background: #fef8ec;
}

.confirm-modal-icon-wrapper.csa .confirm-modal-icon path {
  fill: #f1ae15;
}

.confirm-modal.csa {
  min-width: 424px;
  width: 424px;
}

.csa-modal-title {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #333645;
  margin-bottom: 12px;
}

.csa-modal-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #000000;
}

.input-box.admin-panel
  div
  div.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary {
  border: none;
  box-shadow: none;
}

.employees-select.admin-panel.Mui-focused fieldset {
  box-shadow: #6366f1 0 0 0 1px !important;
}

.employees-select.admin-panel fieldset {
  border-width: 1px !important;
  height: 43px;
  margin-top: 5px;
}

.employees-select.admin-panel {
  height: 43px;
}

.add-bonus-toggle.disabled,
.add-bonus-toggle.disabled > .toggle-btn {
  cursor: not-allowed;
}

.add-bonus-toggle.disabled > span {
  color: rgb(194, 194, 194);
}

.employees-select.admin-panel div.MuiSelect-select.MuiSelect-outlined {
  text-align: start;
}

.confirm-modal-icon-wrapper.recalculate .confirm-modal-icon path {
  fill: #4253f0;
}

.confirm-modal-icon.cancel-subscription {
  width: 16px;
  height: 16px;
}

.confirm-modal-icon {
  width: 24px;
  height: 24px;
  margin: auto;
}

.confirm-modal-subtitle {
  max-width: 400px;
  margin: 8px auto;
  margin-bottom: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.delete-confirm-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirm-checkbox-container label {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  margin: 0;
}

.confirm-modal-icon.company path {
  fill: none !important;
  stroke: #4253f0;
}

.confirm-modal-icon path {
  fill: #fb4a59;
}

.employees-popup-profile-paychex {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
  left: 36px;
  z-index: 10;
}

.employees-popup-profile-gusto {
  width: 54px;
  height: 28px;
  position: absolute;
  top: 133px;
  left: 64px;
  z-index: 10;
}

.employees-popup-profile-tooltip {
  padding: 8px;
  width: 260px;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.MuiTooltip-popper.MuiTooltip-popperInteractive {
  z-index: 10000 !important;
}

.confirm-modal-close-wrapper {
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
}

.confirm-modal-close-wrapper:hover {
  background: #f5f5f6 !important;
  border-radius: 50%;
}

.add-member-modal-header .title-sec,
.add-member-modal-header {
  height: 56px;
}

.expenses-modal-input-container {
  display: flex;
  align-items: center;
}

.expenses-modal-input-container.error {
  border: 1px solid #d32f2f !important;
  border-radius: 8px;
}

.not-selected-company-modal-text {
  margin-top: 8px;
  max-width: 376px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  color: #1d1858;
  margin: 0 auto;
  line-height: 24px;
}

.not-selected-company-modal {
  min-width: 425px;
}

.not-selected-company-modal-btn {
  justify-content: space-between;
}

.not-selected-company-modal-btn .company {
  background: #4253f0;
}

.not-selected-company-modal-btn .company:hover {
  background: #fff;
  border: 1px solid #4253f0;
  color: #4253f0;
}

.station-modal-input.error {
  border-color: #d32f2f !important;
}

.station-modal-input {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.expenses-modal-input {
  padding-left: 16px;
  line-height: 21px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.expenses-modal-input-before {
  height: 41px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid #eaecf0;
  background: #f7f8f7;
  border-right: none;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #000000;
}

.stripe-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stripe-card-container {
  border: 1px solid #eef0f2;
  border-radius: 8px;
  height: 40px;
  padding: 10px 20px;
  width: 100%;
}

.subscriptions-modal {
  width: 550px;
}

.subscriptions-subscribe-modal {
  width: 850px;
}

.subscriptions-modal-field {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.subscriptions-subscribe-container-detail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.subscriptions-subscribe-container-detail div {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
}

.subscriptions-subscribe-container-detail div:last-child {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #717e94;
}

.subscriptions-modal-field div {
  width: 100%;
}

.subscriptions-subscribe-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.subscriptions-subscribe-btns {
  justify-content: flex-end;
}

.subscriptions-subscribe-btns button {
  width: auto;
  border-radius: 8px;
}

.subscriptions-subscribe-btns button:last-child {
  width: auto;
  margin-left: 16px;
}

.subscriptions-subscribe-modal-add svg {
  margin-right: 8px;
}

.subscriptions-subscribe-modal-add svg path {
  stroke: #717e94;
}

.subscriptions-subscribe-modal-add {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #717e94;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 8px;
}

.subscriptions-subscribe-modal-add:hover {
  background: rgba(235, 240, 254, 0.3);
}

.subscriptions-subscribe-cards {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.subscriptions-subscribe-card-container {
  width: 100%;
  padding: 16px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.subscriptions-subscribe-card-container .Mui-checked {
  color: #7f56d9 !important;
}

.subscriptions-subscribe-card {
  display: flex;
  align-items: flex-start;
}

.subscription-payment-method-item-info-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.subscriptions-subscribe-card-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subscriptions-subscribe-card-btn.delete {
  color: #fb4a59;
}

.subscriptions-subscribe-card-btn {
  width: auto;
  margin-right: 12px;
  margin-top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #667085;
  padding: 4px 8px;
}

.subscriptions-subscribe-card-btn.delete:hover {
  background: #fb4a591a;
  border-radius: 8px;
}

.subscriptions-subscribe-card-btn.disabled:hover {
  background: transparent;
  border-radius: 8px;
  cursor: default;
}

.subscriptions-subscribe-card-btn:hover {
  background: #f5f5f6;
  border-radius: 8px;
  cursor: pointer;
}

.upload-gc-card {
  width: 320px;
}

.upload-gc-card-title {
  margin-bottom: 12px;
  color: #1d1858;
}

.upload-gc-cards-container {
  max-width: 1150px;
  min-width: 700px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  padding: 24px;
}

.upload-gc-footer {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.upload-gc-card-file {
  display: flex;
  width: 100%;
  padding: 12px;
  border: 1px solid #93a2bd;
  border-radius: 12px;
  position: relative;
}

.upload-gc-card-file svg {
  margin-right: 16px;
}

.upload-gc-card-file-name {
  color: #1d1858;
}

.upload-gc-card-file:hover .upload-gc-card-file-cancel {
  display: flex;
}

.upload-gc-card-file-cancel {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.employee-modal-accordion {
  box-shadow: none !important;
  border: none;
  margin-top: 32px !important;
  margin-bottom: 0 !important;
}

.employee-modal-accordion .MuiAccordionSummary-root {
  border: 1px solid #eef0f2;
  border-radius: 8px;
  background: #eef0f2;
  color: #1d1858;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 40px !important;
  height: 40px;
}

.employee-modal-accordion .MuiCollapse-wrapper .MuiAccordionDetails-root {
  padding: 0 !important;
}

.employee-modal-accordion::before {
  display: none;
}

.employee-form-half-item-container {
  width: calc(50% - 8px);
  padding: 0 2px;
}

.employee-form-half-item-container .form-item {
  width: 100% !important;
}

.employee-more-info {
  margin-left: 44px;
  margin-top: 4px;
  color: #344054;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.custom-checkbox-with-info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.custom-checkbox-with-info svg {
  color: #344054;
}
.flex {
  display: flex;
}
.add-work-area .three-column > div {
  width: calc(100% / 3 - 40px / 3);
}
.add-work-area .form-item,
.new-employees-basic-info .form-item {
  margin: 0 !important;
  width: calc(50% - 8px);
  padding: 0 2px;
}

.new-employees-basic-info label {
  color: #1d1858;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 9.5px 14px;
}

.employees-modal-header {
  display: flex;
  gap: 24px;
}

.employees-modal-header-active {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.employees-active-select {
  height: 24px;
  border-radius: 24px !important;
  border: 1px solid #d4d9de;
  width: 102px;
}

.employees-active-select .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1d1858;
}

.employees-active-select .MuiOutlinedInput-notchedOutline {
  border: none;
}

.employees-active-select fieldset {
  height: 24px;
}

.employees-active-select:focus {
  border: 1px solid #7b87f5;
  outline: none;
  box-shadow: 0px 0px 0px 4px rgba(64, 139, 250, 0.15);
}

.employees-active-select-container {
  display: flex;
  gap: 4px;
  align-items: center;
}

.employees-active-select-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.employees-modal-header-user-name {
  display: flex;
  align-items: center;
  gap: 6px;
}
.employees-modal-header-user-name p {
  max-width: 150px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1d1858;
}

.employees-modal-header .MuiFormControlLabel-root {
  margin-right: 0;
}

.employees-popup-profile-sec {
  margin-bottom: 22px;
}

.notes-into label {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1d1858;
}

.notes-into div {
  padding-top: 8px;
}

.notes-into div div {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #eef0f2;
}

.employee-modal-tabs {
  margin-bottom: 12px;
  min-height: 34px !important;
  height: 34px;
}

.employee-modal-tabs .MuiTabs-flexContainer {
  gap: 8px;
}

.employee-modal-tabs .MuiTabs-indicator {
  display: none;
}

.employee-modal-tabs .MuiButtonBase-root.MuiTab-root {
  height: 32px;
  min-height: 32px;
  padding: 0;
  color: #1d1858;
  text-transform: capitalize;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #d4d9de;
  border-radius: 8px;
}

.employee-modal-tabs .MuiButtonBase-root.MuiTab-root:hover {
  border-color: #4253f0;
  color: #4253f0;
}

.employee-modal-tabs .MuiButtonBase-root.MuiTab-root.Mui-selected:hover {
  color: #ffffff;
}

.employee-modal-tabs .MuiButtonBase-root.MuiTab-root.Mui-selected {
  background-color: #4253f0;
  color: #ffffff;
}

.employee-btn {
  min-width: 100px;
}

.employee-btn.save:hover {
  background: #fff !important;
  border: 1px solid #4253f0 !important;
}

.employee-btn.cancel:hover {
  border: 1px solid #d4d9de !important;
  background: #d4d9de !important;
}

.employee-btn.cancel {
  background: #ffffff;
  border-color: #d4d9de;
  color: #344054;
}

.employees-modal {
  padding-top: 0;
}

.add-employees-popup-title {
  border-bottom: none;
  padding-bottom: 24px;
  align-items: flex-start;
}

.add-employees-popup-title .add-popup-cancel {
  padding: 0;
}

.add-employees-popup-title h5 {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #1d1858;
  margin: 0;
}

@media screen and (max-width: 750px) {
  .new-employees-basic-info.details .employees-select {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .new-employees-basic-info.details .form-item {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .confirm-modal {
    max-width: 100%;
    min-width: auto;
  }
  .upload-gc-cards-container {
    min-width: 350px;
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
    max-height: 300px;
    overflow: auto;
  }
  .upload-gc-top-bar-container {
    position: relative;
  }
  .upload-gc-top-bar-container > button.popup-close {
    position: absolute;
    top: 24px;
    right: 20px;
  }
}
