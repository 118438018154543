.onboard-main-sec {
  padding-top: 110px;
}
.onboard-head {
  text-align: center;
}
.onboard-add-more {
  width: 100px;
}

.onboard-add-more.add-company-paychex {
  width: 100% !important;
}

.onboard-add-more button {
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  background: transparent;
  display: flex;
  align-items: center;
}
.onboard-add-more button:hover {
  color: #4253f0;
}
.onboard-add-more button span {
  margin-right: 8px;
  font-size: 25px;
}
.onboard-close {
  color: #717e94;
  cursor: pointer;
  margin-left: 8px;
}
.onboard-header-buttons {
  display: flex;
  align-items: center;
}
.onboard-form-fieldset {
  background: #f6f6f6;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
}
.onboard-form {
  max-width: 480px;
  margin: 0 auto;
  margin-top: 30px;
}
.onboard-form .number {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  max-width: 188px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.number span {
  border: 1px solid #d4d9de;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  text-align: center;
  color: #717e94;
  font-size: 30px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}
.onboard-form .number input {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  border: none;
  outline: none;
  text-align: center;
  pointer-events: none;
  width: auto;
  max-width: calc(100% - 100px);
}
.onboard-fieldset-top-bar h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.onboard-fieldset-top-bar {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}
.onboard-form-fieldset .form-item input {
  padding-left: 16px;
}
.onboard-fieldset-item {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.onboard-fieldset-item .form-item {
  margin: 0 0 19px;
}
.onboard-fieldset-item .form-item:first-child {
  width: calc(40%);
}
.onboard-fieldset-item .form-item:last-child {
  width: calc(58% - 10px);
}
.form-item.station-cancel-field .input-box {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.station-field-cancel {
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.form-item.station-cancel-field .input-box input {
  width: calc(100% - 50px);
}

.form-item.station-cancel-field .input-box button {
  background: transparent;
}
.form-item.station-cancel-field .input-box button:hover {
  color: #4253f0;
}
.onboard-fieldset-cancel {
  display: flex;
}
.onboard-fieldset-cancel img {
  max-width: 16px;
  transition: all 0.5s linear;
}
.onboard-fieldset-cancel.up img {
  transform: rotate(180deg);
}

@media screen and (max-width: 500px) {
  .onboard-fieldset-item .form-item {
    width: 100%;
  }
}
