@import '../../../../src/assets/scss/variables';

.activate-subscription {
    min-width: 424px;
    .confirm-modal-wrapper {
        display: flex;
        flex-direction: column;
        .discount-message {
            margin-top: 8px;
            font-family: Gilroy;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $green;
            text-align: start;
        }
        .discount-form {
            display: flex;
            gap: 16px;
            justify-content: space-between;
            align-items: start;
            margin-top: 20px;
            .form-item {
                margin-bottom: 0;
                width: 100%;
                label {
                    margin: 0;
                }
            }
            .discount-button {
                height: 40px;
            }
        }
        .discount-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            font-family: Gilroy;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $primary-blue;

            svg {
                cursor: pointer;
                height: 10px;
                width: 20px;
                &.plus-icon {
                    height: 20px;
                }
                path {
                    fill: $primary-blue;
                }
            }
        }
        .edit-popup-cancel {
            margin-left: auto;
        }
        .confirm-modal-subtitle {
            text-align: start;
            max-width: 376px;
            margin-bottom: 16px;
            color: $dark-blue;
        }
        .activate-subscription-card {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            .stripe-card-container {
                position: relative;
                &.error {
                    border-color: red;
                }
                svg, img {
                    position: absolute;
                    top: 8px;
                    right: 16px;
                }
            }
            .card-title {
                margin-bottom: 8px;
                font-family: Gilroy;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $dark-blue;
                text-align: start;
            }
            .card-number {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .card-more-data {
                display: flex;
                .stripe-card-container:first-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .stripe-card-container:last-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
        .custom-checkbox-container label {
            margin-left: 8px;
            font-family: Gilroy;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $text-light;
        }
        .form-item {
            margin-bottom: 16px;
            label {
                text-align: start;
                color: $dark-blue;
            }
        }
        .total-container {
            border-radius: 8px;
            background-color: $button-grey;
            margin-top: 16px;
            font-family: Gilroy;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $dark-blue;
            padding: 8px 16px 16px;
            .total-container-title {
                margin-bottom: 4px;
                text-align: start;
            }
            .total-container-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .total-weekly-price {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .total-discount {
                        color: $icon-dark-grey;
                        font-family: Gilroy;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-decoration: line-through;
                    }
                }
                .total-container-item-bold {
                    font-weight: 600;
                }
                .total-container-item-regular {
                    font-weight: 400;
                }
            }
        }
    }
}
