@import '../../../../src/assets/scss/variables';

.missing-employee {
    @media screen and (max-width: 991px) {
        width: 536px !important;
        min-width: 536px !important;
    }
    @media screen and (max-width: 536px) {
        width: 100% !important;
        min-width: 100% !important;
        .confirm-modal-wrapper {
            width: 100% !important;
        }
    }
    .confirm-modal-wrapper {
        position: relative;
        width: 536px;
        color: $dark-blue;
        padding: 24px 0;

        .confirm-modal-btn-wrapper {
            gap: 12px;
            justify-content: center;
            .confirm-modal-btn {
                width: 222px;
            }
        }

        .matches-employee-list {
            width: 476px;
            display: flex;
            gap: 16px;
            max-height: 465px;
            overflow: auto;
            flex-direction: column;
            margin: 0 auto;

            @media screen and (max-width: 536px) {
                width: 95%;
            }

            &::-webkit-scrollbar {
                width: 4px;
                background: $light-grey;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary-blue;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: darken($primary-blue, 2);
                cursor: pointer;
            }

            .matches-employee-list-item {
                padding-right: 8px;
                display: flex;
                width: 100%;

                .matches-employee-list-item-name {
                    padding-right: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                    font-family: Gilroy;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $dark-blue;
                    div {
                        text-align: start;
                        overflow: hidden;
                        max-width: 210px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .matches-employee-list-item-select {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .MuiOutlinedInput-root {
                        border-radius: 8px;
                        padding: 3px 36px 3px 12px;
                    }

                    .db path {
                        fill: $green;
                    }
                }
            }
        }

        .matches-employee-title {
            width: 100%;
            display: flex;
            height: 40px;
            border-bottom: 1px solid $light-grey;
            padding: 0 40px;
            margin-bottom: 16px;

            div {
                font-family: Gilroy;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: $text-light;
                width: 50%;
                display: flex;
                align-items: center;
            }
        }

        .edit-popup-cancel {
            position: absolute;
            top: 24px;
            right: 24px;
        }

        .confirm-modal-subtitle {
            margin: 8px auto 16px;
            text-align: center;
            max-width: 456px;
        }
    }
}
