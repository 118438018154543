.service-unavailable {
  width: 100%;
  height: 100%;
  background: #4253f0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-image: url(../images/503/star.svg);
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
}
.service-unavailable-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.service-unavailable-title {
  font-family: Gilroy;
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
  color: #ffffff;
  margin: 24px 0 14px;
}
.service-unavailable-subtitle {
  font-family: Lato;
  font-size: 22px;
  font-weight: 300;
  line-height: 31px;
  color: #ffffff;
  max-width: 730px;
  text-align: center;
}
