.top-title-bar .btn-sec .btn-style + .btn-style {
  margin-left: 12px;
}
.overview-total {
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 30px;
  border: 1px solid #eef0f2;
}
.overview-total-select h4 {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1d1858;
  margin-bottom: 0;
}
.overview-total-select {
  justify-content: space-between;
}
.overview-total ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.custom-tooltip-area p {
  margin: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  color: #8d98a9;
}
.custom-tooltip-area .value,
.custom-tooltip-area .stops {
  color: black;
}
.custom-tooltip-area {
  background: white;
  border-radius: 16px;
  padding: 12px 16px;
  box-shadow: 0px 4px 30px 0px #1f264d26;
  box-shadow: 10px 20px 60px 0px #20202014;
  border-radius: 12px;
}
.overview-total ul li {
  width: 50%;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.overview-total ul li li {
  min-width: 248px;
  width: calc(100% / 2);
  gap: 10px;
  min-width: 266px;
  margin-bottom: 0;
  margin-top: 20px;
  /* border-right: 1px solid #EEF0F2; */
  padding-left: 24px;
  display: flex;
  justify-content: flex-start;
}
.overview-total ul li:last-child li:last-child {
  border-right: 0;
}
.overview-total ul li:nth-child(3n + 1) {
  margin-left: 0;
}
.overview-total ul li h5 {
  width: 100%;
  margin: 0 0 43px;
}
.total-values {
  width: 60%;
}
.total-values h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #1d1858;
  margin: 0 0 8px;
}
.total-values .value-compare {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.total-values .value-compare img {
  max-width: 16px;
}
.total-values .value-compare span {
  color: #16ab6d;
  margin: 0 7px 0 3px;
}
.total-graph {
  width: 40%;
}
.total-graph img {
  width: 100%;
}
.top-title-bar .btn-sec .btn-outline {
  border-color: #d4d9de;
  background: #fff;
  color: #344054;
}
.top-title-bar .btn-sec .btn-outline:hover {
  border-color: #4253f0;
  background: #4253f0;
  color: #fff;
}
.overview-revenue {
  border: 1px solid #eef0f2;
  border-radius: 12px;
  padding: 24px;
  margin: 0 0 30px;
}
.revenue-average-result img {
  width: 100%;
}
.revenue-average {
  color: #344054;
  background: #f6f6f6;
  text-decoration: none;
}

@media screen and (max-width: 1455px) {
  .overview-total ul li li {
    width: calc(100% / 2);
    justify-content: center;
    border-right: 0;
  }
  .overview-total ul li {
    justify-content: center;
  }
}
@media screen and (max-width: 1199px) {
  .total-values h2 {
    font-size: 26px;
    margin: 0 0 6px;
  }
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .overview-total ul li {
    width: 100%;
    margin: 0 0 20px;
  }
  .top-title-bar .btn-sec .btn-style + .btn-style {
    margin-left: 0;
  }
  .btn-sec .btn-style:last-child {
    margin-right: 0;
  }
  .overview-total ul li h5 {
    margin: 0 0 30px;
  }
  .overview-revenue {
    padding: 15px;
  }
  .overview-total ul {
    margin-top: 12px;
  }
  .overview-total ul > li {
    border-right: 0;
  }
  .leaderboard-top-table {
    overflow: auto;
  }
}
