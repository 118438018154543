.payroll-top-total {
  width: 100%;
  height: 136px;
  background: #ebf0fe;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payroll-top-total-item {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  color: #1d1858;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  flex: 1;
}
.payroll-top-total-item-divider {
  width: 1px;
  height: 78px;
  background: #ffffff;
}

.payroll-top-total-item:last-child {
  border-right: none;
}

.payroll-top-total-count {
  font-family: Gilroy;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #4253f0;
  margin-top: 24px;
}

.payroll-top-total-percentage {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #16ab6d;
  height: 24px;
}

.payroll-top-total-name-wrapper {
  display: flex;
  align-items: center;
}

.payroll-top-total-name-wrapper svg {
  margin-right: 6px;
}

@media screen and (max-width: 1300px) {
  .payroll-top-total {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
  }
}
