@import '../../../../src/assets/scss/variables';

.custom-accordion {
  background-color: #F6F6F6 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  margin: 0 !important;
  margin-top: 12px !important;
  margin-bottom: 24px !important;
  padding: 8px 0 !important;

  &::before {
    display: none !important;
  }

  .MuiAccordionSummary-root {
    min-height: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: $dark-blue;
    background-color: $light-grey;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &.Mui-expanded {
      min-height: 24px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 16px 16px 16px;
    font-size: 14px;
    font-weight: 300;
    color: $dark-blue;
    background-color: $light-grey;

    .step {
      padding-bottom: 8px;
    }
  }
}

