@import '../../../../src/assets/scss/variables';

.button-style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  text-decoration: none;
  padding: 11px 16px;
  min-width: 100px;
  .loader svg {
    margin: 0;
  }
  svg {
    margin-right: 8px;
  }
  &.grey-bg:disabled, &.blue-bg:disabled, &.red-bg:disabled, &.green-bg:disabled {
    border-color: $button-grey;
    background: $button-grey;
    color: $icon-dark-grey;
    &:hover {
      border-color: $button-grey;
      background: $button-grey;
      color: $icon-dark-grey;
    }
  }
  &.blue-border:disabled, &.grey-border:disabled {
    border-color: $button-grey;
    background: transparent !important;
    color: $icon-dark-grey;
    &:hover {
      border-color: $button-grey;
      background: transparent !important;
      color: $icon-dark-grey;
    }
  }
  &.grey-bg {
    background: $button-grey;
    border: solid 1px $button-grey;
    color: $text-light;
    svg path {
      stroke: $text-light;
    }
    &:hover {
      background: darken($color: $button-grey, $amount: 10);
      border-color: darken($color: $button-grey, $amount: 10);
    }
    &:active {
      background: darken($color: $button-grey, $amount: 20);
      border-color: darken($color: $button-grey, $amount: 20);
    }
  }
  &.blue-bg {
    background: $primary-blue;
    border: solid 1px $primary-blue;
    color: #FFFFFF;
    svg path {
      stroke: #FFFFFF;
    }
    &:hover {
      background: darken($color: $primary-blue, $amount: 10);
      border-color: darken($color: $primary-blue, $amount: 10);
    }
    &:active {
      background: darken($color: $primary-blue, $amount: 20);
      border-color: darken($color: $primary-blue, $amount: 20);
    }
  }
  &.blue-border {
    background: transparent;
    border: solid 1px $primary-blue;
    color: $primary-blue;
    svg path {
      stroke: $primary-blue;
    }
    &:hover {
      color: darken($color: $primary-blue, $amount: 10);
      border-color: darken($color: $primary-blue, $amount: 10);
    }
    &:active {
      color: darken($color: $primary-blue, $amount: 20);
      border-color: darken($color: $primary-blue, $amount: 20);
    }
  }
  &.grey-border {
    background: transparent;
    border: solid 1px $icon-dark-grey;
    color: $text-light;
    svg path {
      stroke: $text-light;
    }
    &:hover {
      border-color: $text-light;
    }
    &:active {
      border-color: darken($color: $text-light, $amount: 10);
    }
  }
  &.red-bg {
    background: $red;
    border: solid 1px $red;
    color: #FFFFFF;
    svg path {
      stroke: #FFFFFF;
    }
    &:hover {
      background: darken($color: $red, $amount: 10);
      border-color: darken($color: $red, $amount: 10);
    }
    &:active {
      background: darken($color: $red, $amount: 20);
      border-color: darken($color: $red, $amount: 20);
    }
  }
  &.green-bg {
    background: $green;
    border: solid 1px $green;
    color: #FFFFFF;
    svg path {
      stroke: #FFFFFF;
    }
    &:hover {
      background: darken($color: $green, $amount: 10);
      border-color: darken($color: $green, $amount: 10);
    }
    &:active {
      background: darken($color: $green, $amount: 20);
      border-color: darken($color: $green, $amount: 20);
    }
  }
}
