.leaderboard-data-list {
  background: #ffffff;
  border: 1px solid #eef0f2;
  border-radius: 12px;
  padding: 24px;
}
.subtop-title-bar {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.subtop-title-bar h4 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.subtop-title-bar ul {
  margin: 0;
  padding: 0;
  border: 1px solid #d4d9de;
  border-radius: 8px;
  overflow: hidden;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}
.subtop-title-bar ul li + li {
  border-left: 1px solid #d4d9de;
}
.subtop-title-bar ul button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  background: #fff;
  padding: 9px 16px;
  display: block;
}
.subtop-title-bar ul button:hover,
.subtop-title-bar ul button.active {
  color: #344054;
  background: #f6f6f6;
  text-decoration: none;
}
.leaderboard-row-avatar span div img {
  left: 0 !important;
}
.leaderboard-top-table table thead tr th:nth-child(2) .leaderboard-top-table-header-item {
  justify-content: start;
}
.leaderboard-top-table-header-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 81px;
}
.leaderboard-top-table {
  overflow: hidden;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky !important;
  background-color: white;
  z-index: 1;
}
.first-col {
  left: 0px;
}
.second-col {
  left: 116px;
}
.leaderboard-top-table table {
  width: 100%;
}
.leaderboard-top-table table th {
  padding: 12px 24px;
  background: #f6f6f6;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  text-align: left;
}
.leaderboard-top-table table td {
  padding: 16px 24px;
  border-bottom: 1px solid #eef0f2;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}
.leaderboard-top-table table td:nth-child(2) {
  padding-left: 14px;
  position: relative;
}
.leaderboard-top-table table td:nth-child(2) h6 {
  margin: 0;
  font-weight: 500;
}
.leaderboard-top-table table td:nth-child(2) span {
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
}
.leaderboard-top-table table td:nth-child(2) img {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 36px;
}
.leaderboard-top-table table th:nth-child(1),
.leaderboard-top-table table td:nth-child(1) {
  width: 110px;
}
.leaderboard-top-table table th:nth-child(1) img {
  margin-left: 7px;
  max-width: 10px;
  vertical-align: middle;
}
.leaderboard-top-table table th:nth-child(2) ~ th,
.leaderboard-top-table table td:nth-child(2) ~ td {
  text-align: center;
}
.rank-sec {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.rank-sec .rank {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.rank-sec .rank img {
  margin-right: 6px;
  max-width: 10px;
}

.yellow-item {
  background: #fef8ec;
  border-radius: 8px;
  color: #f1ae15;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
.green-item {
  background: #e8f7f0;
  border-radius: 8px;
  color: #16ab6d;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
.blue-item {
  background: #ebf0fe;
  border-radius: 8px;
  color: #4253f0;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
.red-item {
  background: #fceef5;
  border-radius: 8px;
  color: #fc5371;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}

@media screen and (max-width: 1199px) {
  .leaderboard-top-table table {
    min-width: 800px;
  }
}
@media screen and (max-width: 991px) {
  .leaderboard-data-list {
    padding: 15px;
  }
  .leaderboard-top-table table td {
    padding: 13px 15px;
  }
  .leaderboard-top-table table th {
    padding: 10px 15px;
  }
  .leaderboard-top-table table th:nth-child(1),
  .leaderboard-top-table table td:nth-child(1) {
    width: 95px;
  }
  .leaderboard-top-table table td:nth-child(2) img {
    left: 15px;
  }
  .leaderboard-top-table table td:nth-child(2) {
    padding-left: 14px;
  }
}
@media screen and (max-width: 767px) {
  .leaderboard-top-table table {
    min-width: 690px;
  }
  .subtop-title-bar h4 {
    margin: 0 0 15px;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
}
