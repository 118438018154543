@import '../../../src/assets/scss/variables';

.run-payroll-process {
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    gap: 48px;
    padding: 0;
    max-width: none;
    flex-wrap: wrap;
  }

  .stepper-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 250px;

    .stepper-step {
      display: flex;
      gap: 16px;
      cursor: pointer;

      .stepper-step-number-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        .stepper-step-number-container {
          border: 1px solid $beige;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          .stepper-step-number {
            font-family: Lato; // only Lato is OK
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: $beige;
          }
        }

        .stepper-step-number-line {
          width: 1.5px;
          height: 10px;
          border-radius: 3px;
          background: $beige;
          margin-top: 2px;

          &.last-step {
            display: none;
          }
        }
      }

      .stepper-step-name {
        height: 31px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $light-beige;
      }

      .stepper-step-count {
        color: $dark-blue;
        font-family: Gilroy;
        font-size: 11px;
        margin-top: -5px;
      }

      .stepper-step-name.active {
        font-family: Gilroy-Bold;
      }

      &.completed {
        .stepper-step-number-container {
          background: $green;
          border-color: $green;

          .stepper-step-number {
            color: $white;
            display: flex;
          }
        }

        .stepper-step-number-line {
          background: $green;
        }

        .stepper-step-name {
          color: $dark;
        }
      }

      &.filled {
        .stepper-step-number-container {
          background: $primary-blue;
          border-color: $primary-blue;

          .stepper-step-number {
            color: $white;
          }
        }

        .stepper-step-number-line {
          background: $primary-blue;
        }

        .stepper-step-name {
          color: $dark;
        }
      }

      &.active {
        .stepper-step-number-container {
          background: $white;
          border-color: $primary-blue;

          .stepper-step-number-container-number {
            background: $primary-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
          }

          .stepper-step-number {
            color: $white;
          }
        }

        .stepper-step-number-line {
          background: $primary-blue;
        }

        .stepper-step-name {
          color: $dark;
        }
      }
    }
  }
}

.step-content {
  display: flex;
  flex-direction: column;
  width: 550px;

  .step-content-files-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    max-height: 84px;
    overflow: auto;
    padding-right: 3px;


    &::-webkit-scrollbar {
      width: 4px;
      margin-left: 8px;
      background: $light-grey;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-blue;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken($primary-blue, 2);
      cursor: pointer;
    }

    &.small {
      max-height: 84px;
    }

    &.medium {
      max-height: 130px;
    }

    &.big {
      max-height: 220px;
    }

    .step-file {
      align-items: center;
      justify-content: flex-start;
      height: 64px;
      background: $light-dark-grey;
      margin-bottom: 12px;
      padding: 12px;

      .step-file-btn {
        background: transparent;
        margin-left: auto;
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
        width: 30px;
        border-radius: 4px;

        svg path {
          fill: $icon-dark-grey;
        }

        &:hover {
          background: $beige-border;

          svg path {
            fill: $dark-grey;
          }
        }
      }

      .text-container {
        gap: 8px;

        .title {
          color: $text-dark-dark;
        }

        .description {
          color: $text-light-grey;
        }
      }
    }
  }

  .step-error {
    width: 100%;
    height: 71px;
    background: $light-red;
    margin-bottom: 24px;
    border-radius: 8px;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        margin: 0;
        color: $text-dark-dark;
      }

      .description {
        font-size: 12px;
        font-weight: 400;
        line-height: 12.1px;
        text-align: start;
        margin: 0;
        color: $text-dark-blue;
      }
    }
  }

  .form-item {
    margin-bottom: 0;
  }

  .next-button {
    width: 100%;
    height: 48px;
    background: $primary-blue;
    color: white;
    border-radius: 8px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 28px;

    &:hover {
      border: 1px solid $primary-blue;
      color: $primary-blue;
      background: transparent;
    }
  }

  .back-skip-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      width: 100px;
      height: 24px;
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4253F0;
      background: transparent;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:hover {
        color: #5a69f1;

        svg path {
          stroke: #5a69f1;
        }
      }

      &.skip svg {
        transform: rotate(180deg);
      }

      svg {
        width: 6px;

        path {
          stroke: #4253F0;
        }
      }
    }
  }

  .title {
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-weight: 500;
    color: $text-dark;
    margin-bottom: 8px;
  }

  .description {
    color: $text-light;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
  }

  .benefits-container {
    margin-top: 24px;

    .title {
      font-size: 16px;
      text-align: center;
      line-height: 24px;
      font-weight: 500;
      color: $text-dark-blue;
      margin-bottom: 16px;
    }

    .benefit-overview-container {
      display: flex;
      justify-content: center; /* Centers the grid horizontally */
      align-items: center; /* Centers the grid vertically */

      .benefits-overview {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px 40px;
        justify-content: center;
        align-items: start;
        text-align: left;

        .benefit-item {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          color: $text-light;

          svg {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.dropdown-zone {
  background: transparent;
  border: 1px dashed $beige-border;
  border-radius: 12px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 24px 0;
  min-height: 178px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  input[type="file"] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  h6 {
    color: $text-grey;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 9px;
  }

  h6:last-child{
    color: $primary-blue;
    font-size: 16px;
    font-width: 400!important;
  }
  img {
    max-width: 32px;
  }
}

.time-card-upload-container {
  margin-top: 8px;

  .upload-file {
    display: flex;
    padding: 8px 0;
    margin-bottom: 16px;

    .upload-file-card-title {
      color: black;
      flex: 0 1 auto;
      width: 180px;
      white-space: nowrap; /* Prevents text from wrapping to a new line */
      overflow: hidden; /* Hides any overflow */
      text-overflow: ellipsis; /* Adds "..." to indicate truncated text */
      text-align: left;
      align-self: center;
    }

    .dropdown-text {
      background: transparent;
      position: relative;
      z-index: 0;
      overflow: hidden;
      margin: 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      h6 {
        color: $text-grey;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;

        strong {
          font-weight: 600;
          color: $primary-blue;
        }
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
      }

      input[type="file"] {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
      }

      svg {
        max-width: 20px;

        path {
          stroke: $primary-blue;
        }
      }
    }
  }
}
